<template>
  <div class="main-container mb-5">
    <WebLoader v-if="loading" />
    <h1 class="slogan-text ">FIELD FELLAS</h1>
    <b-container v-if="filteredBookings.length > 0">
      <b-row>
        <b-col md="6" class="flex-responsive mb-5" v-for="booking in filteredBookings" :key="booking.id">
          <b-card class="booking-card" :title="booking.club_name" :img-src="booking.turf_image" img-alt="Turf Image">
            <div class="booking-info">
              <b-row>
                <b-col md="6" sm="6" xs="6" class="pb-2">
                  <strong>Booking Id:</strong>
                </b-col>
                <b-col md="6" sm="6" xs="6" class="pb-2 left-border">
                  {{ booking.id }}
                </b-col>
                <b-col md="6" sm="6" xs="6" class="pb-2">
                  <strong>Turf Name:</strong>
                </b-col>
                <b-col md="6" sm="6" xs="6" class="pb-2 left-border">
                  {{ booking.turf_name }}
                </b-col>
                <b-col md="6" sm="6" xs="6" class="pb-2">
                  <strong>Total Amount:</strong>
                </b-col>
                <b-col md="6" sm="6" xs="6" class="pb-2 left-border">
                  {{ booking.total_cost }}
                </b-col>
                <!-- <b-col md="6" sm="6" xs="6" class="pb-2">
                <strong>Remaining Time:</strong>
              </b-col>
              <b-col md="6" sm="6" xs="6" class="pb-2 left-border">
                {{ formatRemainingTime(booking.remainingTime) }}
                <CountDown :endTime="booking.timer" />
              </b-col> -->
                <b-col md="6" sm="6" xs="6" class="pb-2">
                  <strong>Time Slot:</strong>
                </b-col>
                <b-col md="6" sm="6" xs="6"
                  class="pb-2 left-border d-flex g-5 justify-content-start flex-wrap m-0 px-07">
                  <div class="" v-for="slot in mergedTimeSlots.find(
                    (b) => b.id === booking.id
                  ).timeslots" :key="slot.id">
                    {{ slot.time_slot }}
                  </div>
                </b-col>
                <!-- here i need jaazcash number input field -->
                <!-- <b-col md="12" sm="12" xs="12" class="mt-2 pb-2">
                  <strong>JazzCash Mobile Number:</strong>
                </b-col>
                <b-col md="12" sm="12" xs="12" class="pb-2">
                  <b-form-input v-model="jazzCashNumber" type="text" placeholder="Enter Mobile Number"
                    maxlength="11"></b-form-input>
                </b-col> -->

                <div class="payment-buttons">
                  <b-button :variant="isTimeUp(booking.id) ? 'secondary' : 'primary'" :disabled="isTimeUp(booking.id)"
                    @click="makePayment(booking.id)">
                    Pay Now
                  </b-button>
                  <!-- i need cancel booking button  -->
                  <b-button :variant="isTimeUp(booking.id) ? 'secondary' : 'danger'" :disabled="isTimeUp(booking.id)"
                    @click="cancelBookingModal(booking.id)">
                    Cancel Booking
                  </b-button>
                </div>
                <div class="countdown-timer">
                  <strong class="text-center">
                    <i class="fas fa-clock"></i>
                    Remaining Time:</strong>
                  <!-- <CountDown :endTime="booking.timer" /> -->
                  <CountDown :endTime="booking.timer" @time-up="handleTimeUp(booking.id)" />
                </div>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <b-container v-else>
      <b-row>
        <b-col md="12">
          <h1 class="py-5 h-100 text-center d-flex justify-content-center align-items-center">
            <strong>
              <i class="fas fa-exclamation-triangle text-danger"></i>
              No Bookings Found
            </strong>
          </h1>
        </b-col>
        <b-col md="12" class="text-center">
          <b-button variant="danger" @click="$router.push({ name: 'home' })" class=" btn-danger my-5">Back to
            Home</b-button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import CountDown from "./CountDown.vue";
import axios from "axios";
import { BASE_URL } from "@/../config";
import Swal from "sweetalert2";


export default {
  components: {
    CountDown,

  },
  data() {
    return {
      bookings: [],
      timeUpBookings: [],
      loading: false,
      jazzCashNumber: ""
    };
  },
  computed: {
    filteredBookings() {
      return this.bookings.filter(
        (booking, index, self) =>
          booking.booking_status === "temp_booked" &&
          self.findIndex((b) => b.id === booking.id) === index
      );
    },
    mergedTimeSlots() {
      return this.bookings.map((booking) => {
        const mergedSlots = [];
        const sortedSlots = booking.timeslots.sort((a, b) => {
          return this.getTime(a.time_slot) - this.getTime(b.time_slot);
        });
        let currentSlot = sortedSlots[0];
        for (let i = 1; i < sortedSlots.length; i++) {
          const slot = sortedSlots[i];
          if (this.isAdjacent(currentSlot.time_slot, slot.time_slot)) {
            currentSlot.time_slot = this.mergeSlots(
              currentSlot.time_slot,
              slot.time_slot
            );
          } else {
            mergedSlots.push(currentSlot);
            currentSlot = slot;
          }
        }
        mergedSlots.push(currentSlot);
        return { ...booking, timeslots: mergedSlots };
      });
    },
  },
  methods: {
    cancelBookingModal(bookingId) {
      Swal.fire({
        title: "Booking Cancellation!",
        text: "Are you sure you want to cancel this booking?",
        icon: "warning",
        iconColor: "#d33",
        confirmButtonText: "OK",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
      }).then((result) => {
        if (result.isConfirmed) {
          this.cancelBooking(bookingId); // Call cancelBooking if confirmed
        }
      });
    },
    routeToPayment() {
      this.$router.push("/pending");
    },
    getTime(timeSlot) {
      const [start] = timeSlot.split("-");
      return new Date(`1970-01-01T${start}Z`).getTime();
    },

    isAdjacent(slot1, slot2) {
      const [end1] = slot1.split("-").reverse();
      const [start2] = slot2.split("-");
      return end1 === start2;
    },
    mergeSlots(slot1, slot2) {
      const [start1] = slot1.split("-");
      const [end2] = slot2.split("-").reverse();
      return `${start1}-${end2}`;
    },
    routeToPending() {
      this.$router.push("/payment");
    },
    routeToBack() {
      this.$router.push("/tslot");
    },
    // makePayment(bookingId) {
    //   if (!this.jazzCashNumber || this.jazzCashNumber.trim() === "") {
    //     this.$showErrorMessageToast("Please enter your JazzCash mobile number");
    //     return;
    //   }

    //   if (!/^\d{11}$/.test(this.jazzCashNumber)) {
    //     this.$showErrorMessageToast("Please enter a valid 11-digit mobile number");
    //     return;
    //   }

    //   const payload = {
    //     booking_id: bookingId,
    //     customer_id: this.$store.state.user.id,
    //     mobile_no: this.jazzCashNumber,
    //   };

    //   console.log("Hitting payment API with payload:", payload);

    //   this.loading = true;

    //   axios
    //     .post(`${BASE_URL}/create-payment`, payload, {
    //       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    //     })
    //     .then((response) => {
    //       console.log("Payment response:", response.data);
    //       this.$showSuccessMessageToast("Payment initiated successfully!");
    //       // Optionally navigate to a success or pending page
    //       // this.$router.push({ name: "payment-success" });
    //     })
    //     .catch((error) => {
    //       console.error("Payment error:", error);
    //       this.$showErrorMessageToast("Failed to initiate payment. Please try again.");
    //     })
    //     .finally(() => {
    //       this.loading = false;
    //     });
    // },
    makePayment(bookingId) {
      Swal.fire({
        title: "JazzCash Payment",
        text: "Enter your JazzCash mobile number to proceed",
        icon: "info",
        input: "text",
        inputPlaceholder: "Enter your 11-digit JazzCash number",
        inputAttributes: {
          maxlength: 11,
          pattern: "\\d*",
        },
        confirmButtonText: "Pay Now",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        preConfirm: (number) => {
          if (!number || number.trim() === "") {
            Swal.showValidationMessage("Please enter your JazzCash mobile number");
            return false;
          }
          if (!/^\d{11}$/.test(number)) {
            Swal.showValidationMessage("Please enter a valid 11-digit mobile number");
            return false;
          }
          return number;
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const jazzCashNumber = result.value;

          const payload = {
            booking_id: bookingId,
            customer_id: this.$store.state.user.id,
            mobile_no: jazzCashNumber,
          };

          console.log("Hitting payment API with payload:", payload);

          this.loading = true;

          axios
            .post(`${BASE_URL}/create-payment`, payload, {
              headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((response) => {
              console.log("Payment response:", response.data);
              this.$showSuccessMessageToast("Payment initiated successfully!");
              this.fetchCustomerBookings(); // Refresh bookings after successful payment
            })
            .catch((error) => {
              console.error("Payment error:", error);
              this.$showErrorMessageToast("Failed to initiate payment. Please try again.");
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    cancelBooking(bookingId) {
      this.loading = true;
      axios
        .post(
          `${BASE_URL}/cancel-customer-booking`,
          { booking_id: bookingId },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          }
        )
        .then((response) => {
          // console.log("Booking cancelled:", response.data);
          this.bookings = this.bookings.filter((booking) => booking.id !== bookingId);
          this.$showSuccessMessageToast(response.data.message);
          this.fetchCustomerBookings();
        })
        .catch((error) => {
          console.log("Error cancelling booking:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchCustomerBookings() {
      this.loading = true;
      axios
        .get(`${BASE_URL}/get-customer-booking`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        })
        .then((response) => {
          this.bookings = response.data.data;
        })
        .catch((error) => {
          console.log("Error fetching customer details:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleTimeUp(bookingId) {
      if (!this.timeUpBookings.includes(bookingId)) {
        this.timeUpBookings.push(bookingId);
      }
    },
    isTimeUp(bookingId) {
      return this.timeUpBookings.includes(bookingId);
    },
  },
  mounted() {
    this.fetchCustomerBookings();
  },
};
</script>

<style scoped>
.countdown-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.px-07 {
  padding-inline: 0.7rem;
}

.booking-card {
  width: 500px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.card-img {
  border-bottom-left-radius: 0% !important;
  border-bottom-right-radius: 0% !important;
}

.booking-card img {
  max-height: 260px;
}

.booking-info p {
  margin: 5px 0;
}

.payment-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  gap: 0.5rem;
}

.payment-buttons .btn {
  margin: 5px 0;
}

.card-title {
  text-align: center;
  font-weight: 700;
  color: #00863e;
  font-size: 2rem;
}

.booking-info {
  color: #404041;
  font-size: 1.1rem;
}

.left-border {
  border-left: 1px solid #404041;
}

@media (max-width: 1200px) {
  .card {
    max-width: 450px;
  }

  .booking-card {
    width: 100%;
  }

  .booking-card img {
    max-height: 230px;
  }

  .card-title {
    font-size: 1.5rem;
  }

  .booking-info {
    font-size: 1rem;
  }

  .flex-responsive {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .card {
    max-width: 400px !important;
  }

  .booking-card {
    width: 100%;
  }

  .booking-card img {
    max-height: 200px;
  }

  .card-title {
    font-size: 1.5rem;
  }

  .booking-info {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .card {
    max-width: 375px;
  }

  .booking-card {
    width: 100%;
  }

  .booking-card img {
    max-height: 200px;
  }

  .card-title {
    font-size: 1.5rem;
  }

  .booking-info {
    font-size: 1rem;
  }
}

@media (max-width: 575.5px) {

  /* .card {
    max-width: 350px;
  } */
  /* .booking-card {
    width: 100%;
  } */
  .card-title {
    font-size: 1.2rem;
  }

  .booking-info {
    font-size: 0.9rem;
  }

  /* .left-border {
    border-left: none !important;
  } */
  .col-md-6 {
    border-left: none;
    /* max-width: 80%; */
  }

  .payment-buttons {
    margin-top: 0.5rem;
  }

  .payment-buttons .btn {
    font-size: 0.8rem;
  }
}

@media (max-width: 500px) {
  .card {
    max-width: 350px !important;
  }

  .booking-card {
    width: 100%;
  }

  .booking-card img {
    max-height: 160px;
  }

  .card-title {
    font-size: 1rem;
  }

  .booking-info {
    font-size: 0.8rem;
  }
}

@media (max-width: 400px) {
  .card {
    max-width: 310px !important;
  }

  .booking-card {
    width: 100%;
  }

  .booking-card img {
    max-height: 140px;
  }

  .card-title {
    font-size: 1rem;
  }

  .booking-info {
    font-size: 0.8rem;
  }
}

@media (max-width: 350px) {
  .card {
    max-width: 260px !important;
  }

  .booking-card {
    width: 100%;
  }

  .booking-card img {
    max-height: 140px;
  }

  .card-title {
    font-size: 0.9rem;
  }

  .booking-info {
    font-size: 0.7rem;
  }
}
</style>
