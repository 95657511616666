<template>
  <b-navbar toggleable="lg">
    <img class="navbar-logo" src="../assets/main-logo.svg" alt="Not-Found" />

    <b-navbar-toggle target="nav-collapse" class="custom-navbar-toggle"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/"> Home</b-nav-item>
        <b-nav-item v-if="!isServiceProvider && !isAdmin" to="/club"> Club</b-nav-item>
        <b-nav-item v-if="!isServiceProvider && !isAdmin" to="/booking"> Booking</b-nav-item>
        <b-nav-item v-if="isServiceProvider" to="/add-turf">Add Turf</b-nav-item>
        <b-nav-item v-if="isServiceProvider" to="/update-turf">Update Turf</b-nav-item>
        <b-nav-item v-if="isAdmin" to="/customer">Customer</b-nav-item>
        <b-nav-item v-if="isAdmin" to="/vendor">Vendor</b-nav-item>
        <b-nav-item to="/contact"> Contact</b-nav-item>
        <b-nav-item-dropdown right v-if="isUser">
          <template #button-content>
            <b-avatar size="1.5rem" variant="secondary" class="mr-2" style="background-color: #555555; color: white">
              {{ $store.state.user.name.charAt(0) }}
            </b-avatar>
            {{ $store.state.user.name }}
          </template>
          <b-dropdown-item @click="goToProfile"> My Profile</b-dropdown-item>
          <b-dropdown-item @click="changePassword">
            Change Password</b-dropdown-item>
          <b-dropdown-item @click="logout">Logout</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav>
        <b-nav-item v-if="!$store.state.user">
          <!-- <b-button  class="login-btn" >Login/Signup</b-button> -->
          <router-link to="/login">
            <b-button class="login-btn">Login/Signup</b-button>
          </router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link to="/vendor-register">
            <b-button class="turf-btn">List your Turf</b-button>
          </router-link>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "NavbarView",

  computed: {
    isUser() {
      return this.$store.state.user;
    },
    isServiceProvider() {
      return this.$store.state.user && this.$store.state.user.role_type === 'service_provider'
    },
    isCustomer() {
      return this.$store.state.user && this.$store.state.user.role_type === 'customer'
    },
    isAdmin() {
      return this.$store.state.user && this.$store.state.user.role_type === 'admin'
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      this.$store.commit("clearUser");
      this.$router.push({ name: "login" });
    },
    goToProfile() {
      this.$router.push("/my-profile").catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });
    },
    changePassword() {
      this.$router.push("/change-password").catch((err) => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });
    },
  },
};
</script>

<style>
/* .navbar-nav .dropdown-menu {
  max-width: 80%;
  margin: auto;
} */
.dropdown-item.active,
.dropdown-item:active {
  background-color: #555555 !important;
}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Media Queries  */

@media (max-width: 1199px) {
  .navbar-logo {
    height: 70px !important;
  }

  .navbar {
    padding-inline: 1rem !important;
  }

  .nav-link {
    font-size: 1rem !important;
  }

  .login-btn {
    font-size: 0.9rem !important;
    padding: 0.5rem 1rem !important;
  }

  .turf-btn {
    font-size: 0.9rem !important;
    padding: 0.5rem 1rem !important;
  }
}

@media (max-width: 991px) {
  .navbar-collapse {
    text-align: center !important;
    flex-direction: column !important;
  }
}

@media (max-width: 767px) {
  .navbar {
    padding-inline: 0.5rem !important;
  }
}

@media (max-width: 575px) {
  .custom-navbar-toggle {
    padding: 2px 8px !important;
    margin-right: 10px !important;
  }

  .navbar-toggler-icon {
    width: 1em !important;
  }
}

@media (max-width: 479px) {
  .custom-navbar-toggle {
    margin-right: 0px !important;
  }
}
</style>
