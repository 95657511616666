<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <!-- Left section of the footer -->
          <div class="footer-left">
            <img
              class="footer-img"
              src="../assets/tagline-logo.svg"
              alt=""
              srcset=""
            />
          </div>
        </div>
        <div class="col-md-4">
          <!-- Center section of the footer -->
          <div class="footer-center">
            <h3>Contact Us</h3>
            <address>
              <!-- Phone icon -->
              <p>
                <!-- <i class="fas fa-sharp fa-regular fa-phone-volume"></i>  -->
                <img src="../assets/phone.png" alt="" srcset="" />
                +92-327-0877831
              </p>
              <!-- Address icon -->

              <!-- Email icon -->
              <p>
                <!-- <i class="fas fa-envelope"></i>  -->
                <img src="../assets/email.png" alt="" srcset="" />
                fieldfellas@gmail.com
              </p>

              <p>
                <!-- <i class="fas fa-map-marker-alt"></i> -->
                <img src="../assets/location.png" alt="" srcset="" />
                PGECHS PH-II, Lahore
              </p>
              <!-- Google Maps iframe -->
              <!-- <div class="mapouter">
                  <div class="gmap_canvas">
                    <iframe
                      class="gmap_iframe"
                      width="100%"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                      src="https://maps.google.com/maps?width=300&amp;height=150&amp;hl=en&amp;q=Defence%20Road%20Lahore&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    ></iframe>
                    <a href="https://connectionsgame.org/"
                      >Connections Unlimited</a
                    >
                  </div>
                </div> -->
            </address>
          </div>
        </div>
        <div class="col-md-4 explore-us">
          <!-- Right section of the footer -->
          <div class="footer-right">
            <h3>Explore Us</h3>
            <div class="row">
              <div class="col-md-6">
                <ul class="explore-link">
                  <p>
                    <a href="/">Home</a>
                  </p>
                  <p>
                    <a href="/booking">Booking</a>
                  </p>
                  <p>
                    <a href="/contact">Contact Us</a>
                  </p>
                </ul>
              </div>
              <div class="col-md-6">
                <ul class="explore-link">
                  <p>
                    <a href="/club">Club</a>
                  </p>
                  <p>
                    <a href="/">About Us</a>
                  </p>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-12 d-flex">
          <div class="footer-below pt-3">
            <h3 class="mb-2">Let's Stay In Touch</h3>
            <ul class="social-icons">
              <li>
                <a href="#">
                  <img
                    class="twitter"
                    src="../assets/facebook.png"
                    alt=""
                    srcset=""
                  />
                </a>
              </li>
              <li>
                <a href="#"
                  ><img
                    class="twitter"
                    src="../assets/twitter.png"
                    alt=""
                    srcset=""
                /></a>
              </li>
              <li>
                <a href="#"
                  ><img
                    class="twitter"
                    src="../assets/instagram.png"
                    alt=""
                    srcset=""
                /></a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12">
          <div class="copyright">
            <p>&copy; 2023 Field Fellas. All rights reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterView",
};
</script>

<style scoped>
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 150px;
}

.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 150px;
}

.gmap_iframe {
  height: 150px !important;
}

@media (max-width: 991px) {


  /* .footer-img {
    height: 100px;
  }
  .footer-left {
    text-align: center;
  } */
  .explore-us {
    padding-left: 1.5rem
  }
}

@media (max-width: 768px) {
  .container {
    margin: 0px !important;
  }
  .footer {
    display: flex;
    justify-content: center;
  }
  .footer-left {
    text-align: center;
    margin-bottom: 2rem;
  }
  .footer-center {
    text-align: center;
  }
  .footer-right {
    text-align: center;
  }
  .explore-us {
    padding-left: 0.725rem
  }
  
}
</style>
