<template>
    <div class="tap-top" :style="[display ? { 'display': 'block' } : { 'display': 'none' }]" @click="executeScroll">
        <i class="fas fa-arrow-up"></i>
    </div>
</template>

<script>
export default {
    name: 'TapTop',
    data() {
        return {
            display: false,
        };
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        executeScroll() {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        },
        handleScroll() {
            if (window.scrollY > 400) {
                this.display = true;
            } else {
                this.display = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.tap-top {
    text-align: center;
    cursor: pointer;
    display: none;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    position: fixed;
    bottom: 30px;
    right: 80px;
    z-index: 5;
    color: #fff;
    background: #00853e;
    border: 1px solid #eff4ff;
    border: none;
    padding: 7px;
    box-shadow: 0 0 10px #2b4e66;
    opacity: 0.5;
    transition: all 0.3s ease;

    &:hover {
        transition: all 0.3s ease;
        opacity: .8;
    }

    svg {
        width: 20px;
    }
}
</style>